import { AUTH, LOGOUT } from "../constants/actionTypes";

type AuthState = {
	authData: string | null;
};

type AuthAction = {
	data: {
		id: string;
	};
	type: string;
};
const authReducer = (
	state: AuthState = { authData: null },
	action: AuthAction
) => {
	switch (action.type) {
		case AUTH:
			localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
			return { ...state, authData: action?.data };
		case LOGOUT:
			localStorage.clear();
			return { ...state, authData: null };
		default:
			return state;
	}
};

export default authReducer;
