import {
	FETCH_ALL,
	CREATE,
	UPDATE,
	DELETE,
	LIKE,
	FETCH_BY_SEARCH,
	START_LOADING,
	END_LOADING,
	FETCH_POST,
} from "../constants/actionTypes";
import PostType from "src/types/Post";

type PostState = {
	isLoading: boolean;
	posts: PostType[];
};

type PostAction = {
	payload: {
		_id: string;
		data: string;
		currentPage: string;
		numberOfPages: number;
		deleteId: string;
	};
	type: string;
};

export default (
	state: PostState = { isLoading: true, posts: [] },
	action: PostAction
) => {
	switch (action.type) {
		case START_LOADING:
			return { ...state, isLoading: true };
		case END_LOADING:
			return { ...state, isLoading: false };
		case FETCH_ALL:
			return {
				...state,
				posts: action.payload.data,
				currentPage: action.payload.currentPage,
				numberOfPages: action.payload.numberOfPages,
			};
		case FETCH_POST:
			return {
				...state,
				post: action.payload,
			};
		case FETCH_BY_SEARCH:
			return {
				...state,
				posts: action.payload,
			};
		case CREATE:
			return { ...state, posts: [...state.posts, action.payload] };
		case UPDATE:
		case LIKE:
			return {
				...state,
				posts: state.posts.map((post) =>
					post._id === action.payload._id ? action.payload : post
				),
			};
		case DELETE:
			return {
				...state,
				posts: state.posts.filter(
					(post) => post._id !== action.payload.deleteId
				),
			};
		default:
			return state;
	}
};
