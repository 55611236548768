import { useEffect } from "react";
import {
	Paper,
	Typography,
	CircularProgress,
	Divider,
} from "@material-ui/core";
import { getPostsBySearch } from "../../actions/posts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router";
import { getPost } from "../../actions/posts";

import useStyles from "./styles";
import malicious_feet from "../../../src/images/logo/malicious_feet.png";
import PostType from "../../types/Post";

type Posts = {
	posts: PostType[];
	post: PostType;
	isLoading: boolean;
};
type PageState = {
	posts: Posts;
};

const PostDetails = () => {
	const { post, posts, isLoading } = useSelector(
		(state: PageState) => state.posts
	);
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		dispatch(getPost(id));
	}, [id]);

	useEffect(() => {
		if (post) {
			dispatch(
				getPostsBySearch({ search: "none", tags: post?.tags.join(",") })
			);
		}
	}, [post]);

	if (!post) return null;

	if (isLoading) {
		return (
			<Paper elevation={6} className={classes.loadingPaper}>
				<CircularProgress size="7em" />
			</Paper>
		);
	}

	const recommendedPosts = posts.filter(({ _id }) => _id !== post._id);

	const openPost = (_id: string) => history.push(`/posts/${_id}`);

	return (
		<Paper style={{ padding: "20px", borderRadius: "15px" }} elevation={6}>
			<div className={classes.card}>
				<div className={classes.section}>
					<Typography variant="h3" component="h2">
						{post.title}
					</Typography>
					<Typography
						gutterBottom
						variant="h6"
						color="textSecondary"
						component="h2"
					>
						{post.tags.map((tag) => `#${tag} `)}
					</Typography>
					<Typography gutterBottom variant="body1" component="p">
						{post.message}
					</Typography>
					<Typography variant="h6">Created by: {post.name}</Typography>
					<Typography variant="body1">
						{moment(post.createdAt).fromNow()}
					</Typography>
					<Divider style={{ margin: "20px 0" }} />
					<Typography variant="body1">
						<strong>Realtime Chat - coming soon!</strong>
					</Typography>
					<Divider style={{ margin: "20px 0" }} />
					<Typography variant="body1">
						<strong>Comments - coming soon!</strong>
					</Typography>
					<Divider style={{ margin: "20px 0" }} />
				</div>
				<div className={classes.imageSection}>
					<img
						className={classes.media}
						src={post.selectedFile || malicious_feet}
						alt={post.title}
					/>
				</div>
			</div>
			{recommendedPosts.length && (
				<div className={classes.section}>
					<Typography gutterBottom variant="h5">
						You might also like:
					</Typography>
					<Divider />
					<div className={classes.recommendedPosts}>
						{recommendedPosts.map(
							({ title, message, name, likes, selectedFile, _id }) => (
								<div
									style={{ margin: "20px", cursor: "pointer" }}
									onClick={() => openPost(_id)}
									key={_id}
								>
									<Typography gutterBottom variant="h6">
										{title}
									</Typography>
									<Typography gutterBottom variant="subtitle2">
										{name}
									</Typography>
									<Typography gutterBottom variant="subtitle2">
										{message}
									</Typography>
									<Typography gutterBottom variant="subtitle1">
										Likes: {likes.length}
									</Typography>
									<img src={selectedFile} width="200px" />
								</div>
							)
						)}
					</div>
				</div>
			)}
		</Paper>
	);
};

export default PostDetails;
