import { useState } from "react";
import {
	Container,
	Grow,
	Grid,
	Paper,
	AppBar,
	TextField,
	Button,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ChipInput from "material-ui-chip-input";

import Posts from "../Posts/Posts";
import Form from "../Form/Form";
import { getPostsBySearch } from "../..//actions/posts";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import Pagination from "../Pagination";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const Home = () => {
	const [currentId, setCurrentId] = useState<string | null>("");
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = useQuery();
	const history = useHistory();
	const page = query.get("page") || 1;
	const searchQuery = query.get("searchQuery");
	const [search, setSearch] = useState("");
	const [tags, setTags] = useState<string[]>([]);

	const searchPost = () => {
		if (search.trim() || tags) {
			dispatch(getPostsBySearch({ search, tags: tags.join(",") }));
			history.push(
				`/posts/search?searchQuery=${search || "none"}&tags=${
					tags.join(",") || "none"
				}`
			);
		} else {
			history.push("/");
		}
	};

	const handleKeyPress = (e: any) => {
		/// Enter key is 13
		if (e.keycode === 13) {
			searchPost();
		}
	};
	const handleAdd = (tag: string) => setTags([...tags, tag]);
	const handleDelete = (tag: string) => setTags(tags.filter((t) => t !== tag));

	return (
		<Grow in>
			<Container maxWidth="xl">
				<Grid
					container
					className={classes.gridContainer}
					justifyContent="space-between"
					alignItems="stretch"
					spacing={3}
				>
					<Grid item xs={12} sm={6} md={9}>
						<Posts setCurrentId={setCurrentId} />
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<AppBar
							className={classes.appBarSearch}
							position="static"
							color="inherit"
						>
							<TextField
								name="search"
								variant="outlined"
								label="Search Posts"
								onKeyDown={handleKeyPress}
								fullWidth
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
							<ChipInput
								style={{ margin: "10px 0" }}
								value={tags}
								onAdd={handleAdd}
								onDelete={handleDelete}
								label="Search Tags"
								variant="outlined"
							/>
							<Button onClick={searchPost} color="primary" variant="contained">
								Search
							</Button>
						</AppBar>
						<Form currentId={currentId} setCurrentId={setCurrentId} />\
						{!searchQuery && !tags.length && (
							<Paper className={classes.pagination} elevation={6}>
								<Pagination page={page} />
							</Paper>
						)}
					</Grid>
				</Grid>
			</Container>
		</Grow>
	);
};
