import * as api from '../api';
import { AUTH } from '../constants/actionTypes';

export const signin = (formData, history) => async (dispatch) => {
    try {
        const { data } = await api.signin(formData);
        dispatch({ type: AUTH, data })

        history.push("/");
    }catch(error){
        console.log(error);
    }
}

export const signup = (formData, history) => async (dispatch) => {
    try {
        /// After dispatching, this action makes an api call, which is ../api 
        /// The api call returns some data and we assign it to data. 
        const { data } = await api.signup(formData);
        // This is calling the reducers
        dispatch({ type: AUTH, data })

        history.push("/");
    }catch(error){
        console.log(error);
    }
}