import {
	useState,
	useEffect,
	FormEvent,
	SetStateAction,
	Dispatch,
} from "react";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
// @ts-ignore
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import { createPost, updatePost } from "../../actions/posts";

import PostType from "../../types/Post";

type Posts = {
	posts: PostType[];
};
type PageState = {
	posts: Posts;
};

type FormProps = {
	currentId: string | null;
	setCurrentId: Dispatch<SetStateAction<string | null>>;
};

const Form = ({ currentId, setCurrentId }: FormProps) => {
	const [postData, setPostData] = useState({
		title: "",
		message: "",
		tags: [""],
		selectedFile: "",
	});
	const post = useSelector((state: PageState) =>
		currentId
			? state.posts.posts.find((p: PostType) => p._id === currentId)
			: null
	);
	const classes = useStyles();
	const dispatch = useDispatch();
	const localProfile = localStorage.getItem("profile");
	const user = localProfile != null ? JSON.parse(localProfile) : null;
	const history = useHistory();

	useEffect(() => {
		if (post) setPostData(post);
	}, [post]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();

		if (currentId) {
			dispatch(
				updatePost(currentId, { ...postData, username: user?.result.username })
			);
		} else {
			dispatch(
				createPost(
					{ ...postData, username: user?.result.username || user?.result.name },
					history
				)
			);
		}
		clear();
	};

	const clear = () => {
		setCurrentId(null);
		setPostData({ title: "", message: "", tags: [""], selectedFile: "" });
	};

	if (!user?.result?.username) {
		return (
			<Paper className={classes.paper}>
				<Typography variant="h6" align="center">
					Please Sign In to create your own posts and like other's memories.
				</Typography>
			</Paper>
		);
	}

	return (
		<Paper className={classes.paper} elevation={6}>
			<form
				autoComplete="off"
				noValidate
				className={`${classes.root} ${classes.form}`}
				onSubmit={handleSubmit}
			>
				<Typography variant="h6">
					{currentId ? "Editing" : "Creating"} a Memory
				</Typography>
				<TextField
					name="title"
					variant="outlined"
					label="Title"
					fullWidth
					value={postData.title}
					onChange={(e) => setPostData({ ...postData, title: e.target.value })}
				/>
				<TextField
					name="message"
					variant="outlined"
					label="Message"
					fullWidth
					value={postData.message}
					onChange={(e) =>
						setPostData({ ...postData, message: e.target.value })
					}
				/>
				<TextField
					name="tags"
					variant="outlined"
					label="Tags"
					fullWidth
					value={postData.tags}
					onChange={(e) =>
						setPostData({ ...postData, tags: e.target.value.split(",") })
					}
				/>
				<div className={classes.fileInput}>
					<FileBase
						type="file"
						multiple={false}
						onDone={({ base64 }: any) =>
							setPostData({ ...postData, selectedFile: base64 })
						}
					/>
				</div>
				<Button
					className={classes.buttonSubmit}
					variant="contained"
					color="primary"
					size="large"
					type="submit"
					fullWidth
				>
					Submit
				</Button>
				<Button
					variant="contained"
					color="secondary"
					size="small"
					onClick={clear}
					fullWidth
				>
					Clear
				</Button>
			</form>
		</Paper>
	);
};

export default Form;
