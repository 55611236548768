import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { useParams } from "react-router";

import useStyles from "./styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "src/actions/user";
import User from "src/types/User";
import PostType from "src/types/Post";

type UserState = {
	user: User;
	isLoading: boolean;
};
type PageState = {
	users: UserState;
};
const UserDetails = () => {
	const classes = useStyles();

	const { user, isLoading } = useSelector((state: PageState) => state.users);
	const dispatch = useDispatch();

	const { username } = useParams<{ username: string }>();

	useEffect(() => {
		dispatch(getUser(username));
		console.log(user);
	}, [username]);

	if (!user) return null;

	if (isLoading) {
		return (
			<Paper elevation={6} className={classes.loadingPaper}>
				<CircularProgress size="7em" />
			</Paper>
		);
	}

	return (
		<Paper style={{ padding: "20px", borderRadius: "15px" }} elevation={6}>
			<div className={classes.card}>
				<div className={classes.section}>
					<Typography variant="h3" component="h2">
						{JSON.stringify(user)}
					</Typography>
				</div>
			</div>
		</Paper>
	);
};

export default UserDetails;
