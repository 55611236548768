import * as api from "../api";
import { START_LOADING, END_LOADING, GET_USER } from "../constants/actionTypes";

export const getUser = (username) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });

		const { data } = await api.fetchUser(username);
		dispatch({ type: GET_USER, payload: data });
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error.message);
	}
};
