import React, { useState } from "react";
import { Container } from "@material-ui/core";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import PostDetails from "src/components/PostDetails/PostDetails";
import UserDetails from "src/components/UserDetails/UserDetails";
import Navbar from "src/components/Navbar/Navbar";
import { Home } from "src/components/Home/Home";
import Auth from "src/components/Auth/Auth";

const App = () => {
	const [user, setUser] = useState(null);
	const profileData = localStorage.getItem("profile");
	if (profileData) {
		setUser(JSON.parse(profileData));
	}

	return (
		<BrowserRouter>
			<Container maxWidth="xl">
				<Navbar />
				<Switch>
					<Route path="/" exact component={() => <Redirect to="/posts" />} />
					<Route path="/users/:username" exact component={UserDetails} />
					<Route path="/posts" exact component={Home} />
					<Route path="/posts/search" exact component={Home} />
					<Route path="/posts/:id" exact component={PostDetails} />
					<Route
						path="/auth"
						exact
						component={() => (!user ? <Auth /> : <Redirect to="/posts" />)}
					/>
				</Switch>
			</Container>
		</BrowserRouter>
	);
};

export default App;
