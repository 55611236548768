import { SetStateAction } from "react";
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Button,
	Typography,
	ButtonBase,
} from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { deletePost, likePost } from "../../../actions/posts";
import PostType from "../../../types/Post";
import useStyles from "./styles";

import malicious_feet from "../../../../src/images/logo/malicious_feet.png";

type PostProps = {
	post: PostType;
	setCurrentId: (id: SetStateAction<string | null>) => void;
};

const Post = ({ post, setCurrentId }: PostProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const profileData = localStorage.getItem("profile");
	const user = profileData ? JSON.parse(profileData) : null;
	const history = useHistory();

	const Likes = () => {
		if (post.likes.length > 0) {
			return post.likes.find((like: string) => like === user?.result?._id) ? (
				<>
					<ThumbUpAltIcon fontSize="small" />
					&nbsp;
					{post.likes.length > 2
						? `You and ${post.likes.length - 1} others`
						: `${post.likes.length} like${post.likes.length > 1 ? "s" : ""}`}
				</>
			) : (
				<>
					<ThumbUpAltOutlined fontSize="small" />
					&nbsp;{post.likes.length} {post.likes.length === 1 ? "Like" : "Likes"}
				</>
			);
		}
		return (
			<>
				<ThumbUpAltOutlined fontSize="small" />
				&nbsp;Like
			</>
		);
	};

	const openPost = () => {
		history.push(`/posts/${post._id}`);
	};

	return (
		<Card className={classes.card} raised elevation={6}>
			<ButtonBase className={classes.cardAction} onClick={openPost}>
				<CardMedia
					className={classes.media}
					image={post.selectedFile ? post.selectedFile : malicious_feet}
					title={post.title}
				/>
				<div className={classes.overlay}>
					<Typography variant="h6">{post.username}</Typography>
					<Typography variant="body2">
						{moment(post.createdAt).fromNow()}
					</Typography>
				</div>
				{user?.result?._id === post?.creator && (
					<div className={classes.overlay2}>
						<Button
							style={{ color: "white" }}
							size="small"
							onClick={() => setCurrentId(post._id)}
						>
							<MoreHorizIcon fontSize="medium" />
						</Button>
					</div>
				)}
				<div className={classes.details}>
					<Typography variant="body2" color="textSecondary">
						{post.tags.map((tag) => `#${tag} `)}
					</Typography>
				</div>
				<Typography className={classes.title} variant="h5" gutterBottom>
					{post.title}
				</Typography>
				<CardContent>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						gutterBottom
					>
						{post.message}
					</Typography>
				</CardContent>
			</ButtonBase>
			<CardActions className={classes.cardActions}>
				<Button
					size="small"
					color="primary"
					disabled={!user?.result}
					onClick={() => {
						dispatch(likePost(post._id));
					}}
				>
					<Likes />
				</Button>
				{user?.result?._id === post?.creator && (
					<Button
						size="small"
						color="primary"
						onClick={() => dispatch(deletePost(post._id))}
					>
						<DeleteIcon fontSize="small" />
						Delete
					</Button>
				)}
			</CardActions>
		</Card>
	);
};

export default Post;
