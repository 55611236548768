import { GET_USER, START_LOADING, END_LOADING } from "../constants/actionTypes";
import User from "../types/User";

type UserState = {
	isLoading: boolean;
	user: User;
};

type UserAction = {
	payload: User;
	type: string;
};

export default (
	state: UserState = {
		isLoading: true,
		user: {
			username: "test",
			email: "testEmail",
			_id: "testId",
		},
	},
	action: UserAction
) => {
	switch (action.type) {
		case START_LOADING:
			return { ...state, isLoading: true };
		case END_LOADING:
			return { ...state, isLoading: false };
		case GET_USER:
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
};
