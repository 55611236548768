import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	appBar: {
		borderRadius: 15,
		margin: "10px 0px 30px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: "rgba(255,185,200,255)",
	},
	heading: {
		color: "rgba(0,183,255, 1)",
		textDecoration: "none",
	},
	image: {
		marginLeft: "20px",
		marginTop: "20px",
		marginBottom: "20px",
		marginRight: "20px",
	},
	toolbar: {
		display: "flex",
		justifyContent: "flex-end",
		width: "400px",
	},
	profile: {
		display: "flex",
		justifyContent: "space-between",
		width: "400px",
	},
	userName: {
		display: "flex",
		alignItems: "center",
	},
	brandContainer: {
		display: "flex",
		alignItems: "center",
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
	},
}));
