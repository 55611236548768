import React, { useState, useEffect } from "react";
import { AppBar, Avatar, Toolbar, Typography, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import { Link, useHistory, useLocation } from "react-router-dom";
import useStyles from "./styles";
import malicious_feet_logo from "../../images/logo/logo_malicious_feet_word.svg";

type DecodedToken = {
	exp: number;
};

const Navbar = () => {
	const classes = useStyles();
	const profileData = localStorage.getItem("profile");
	const [user, setUser] = useState(
		profileData ? JSON.parse(profileData) : null
	);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const logout = () => {
		dispatch({ type: "LOGOUT" });
		history.push("./");
		setUser(null);
	};

	useEffect(() => {
		const token = user?.token;
		if (token) {
			const decodedToken: DecodedToken = decode(token);
			if (decodedToken.exp * 1000 < new Date().getTime()) {
				logout();
			}
		}

		const profileData = localStorage.getItem("profile");
		if (profileData) {
			setUser(JSON.parse(profileData));
		}
	}, [location]);

	return (
		<AppBar className={classes.appBar} position="static" color="inherit">
			<div className={classes.brandContainer}>
				<Link to="/">
					<img
						className={classes.image}
						src={malicious_feet_logo}
						alt="Malicious Feet"
						height="30px"
					/>
				</Link>
			</div>
			<Toolbar className={classes.toolbar}>
				{user ? (
					<div className={classes.profile}>
						<Avatar
							className={classes.purple}
							alt={user.result?.username}
							src={user.result?.imageUrl}
						>
							{user.result?.username.charAt(0) || user.result?.name?.charAt(0)}
						</Avatar>
						<Typography className={classes.userName} variant="h6">
							{user.result?.username || user.result?.name}
						</Typography>
						<Button variant="contained" color="secondary" onClick={logout}>
							logout
						</Button>
					</div>
				) : (
					<Button
						component={Link}
						to="/auth"
						variant="contained"
						color="primary"
					>
						Sign In / Sign Up
					</Button>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
