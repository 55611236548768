import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../actions/posts";

import useStyles from "./styles";

type PaginateProps = {
	page: string | number;
};

type Posts = {
	numberOfPages: number;
};
type PageState = {
	posts: Posts;
};

const Paginate = ({ page }: PaginateProps) => {
	const { numberOfPages } = useSelector((state: PageState) => state.posts);
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (page) dispatch(getPosts(page));
	}, [page]);

	return (
		<Pagination
			classes={{ ul: classes.ul }}
			count={numberOfPages}
			page={Number(page) || 1}
			variant="outlined"
			color="primary"
			renderItem={(item) => (
				<PaginationItem
					{...item}
					component={Link}
					to={`/posts?page=${item.page}`}
				/>
			)}
		/>
	);
};

export default Paginate;
